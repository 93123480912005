// Sidebar.jsx
import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTachometerAlt,
  faBars,
  faClock,
  faList,
  faUsers,
  faUsersCog,
  faChartLine,
  faDollarSign,
} from '@fortawesome/free-solid-svg-icons';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Firebase Authentication
import '../styles/Sidebar.css'; // Custom sidebar CSS

const Sidebar = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(false);

  const handleCloseSidebar = () => {
    setShowSidebar(false);
  };


  // Listen to authentication state changes
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser); // Set the user data when logged in
      }
    });
    return unsubscribe; // Cleanup on component unmount
  }, []);

  const handleProfileClick = () => {
    navigate('/admin/profile'); // Navigate to profile page
  };

  return (
    <>
      {/* Hamburger icon visible on small screens */}
      <div className="d-lg-none p-2" style={{ backgroundColor: '#3498db' }}>
        <FontAwesomeIcon
          icon={faBars}
          size="lg"
          style={{ cursor: 'pointer', color: '#fff' }}
          onClick={() => setShowSidebar(!showSidebar)}
        />
      </div>

      {/* Sidebar container that toggles with the 'show' class */}
      <div className={`custom-sidebar vh-100 ${showSidebar ? 'show' : ''}`}>
        {/* Paste your existing sidebar content here, for example: */}
        <Navbar expand="lg" className="flex-column vh-100 sticky-top">
          <Container className="flex-column align-items-start p-0">
            <Navbar.Brand className="w-100 p-0 sidebar-header">
              <h1 className="sidebar-title">Imagine Seniorcare</h1>
            </Navbar.Brand>
            <hr className="sidebar-divider" />

            <Nav className="flex-column w-100 custom-nav p-0">
              <Nav.Link
                as={NavLink}
                to="/admin/dashboard"
                className="d-flex align-items-center nav-link-custom"
                onClick={handleCloseSidebar}
              >
                <FontAwesomeIcon icon={faTachometerAlt} className="me-2 fa-icon" />
                Dashboard
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/admin/employee-timecard"
                className="d-flex align-items-center nav-link-custom"
                onClick={handleCloseSidebar}
              >
                <FontAwesomeIcon icon={faClock} className="me-2 fa-icon" />
                Employee Timecard
              </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/admin/timesheets"
              className="d-flex align-items-center nav-link-custom"
              onClick={handleCloseSidebar}
            >
              <FontAwesomeIcon icon={faList} className="me-2 fa-icon" />
              Timesheets
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/admin/client-timesheet"
              className="d-flex align-items-center nav-link-custom"
              onClick={handleCloseSidebar}
            >
              <FontAwesomeIcon icon={faList} className="me-2 fa-icon" />
              Client Timesheets
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/admin/applications"
              className="d-flex align-items-center nav-link-custom"
              onClick={handleCloseSidebar}
            >
              <FontAwesomeIcon icon={faUsers} className="me-2 fa-icon" />
              Applications
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/admin/contracts"
              className="d-flex align-items-center nav-link-custom"
              onClick={handleCloseSidebar}
            >
              <FontAwesomeIcon icon={faUsersCog} className="me-2 fa-icon" />
              Contracts
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/admin/invoices"
              className="d-flex align-items-center nav-link-custom"
              onClick={handleCloseSidebar}
            >
              <FontAwesomeIcon icon={faChartLine} className="me-2 fa-icon" />
              Invoices
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/admin/leads"
              className="d-flex align-items-center nav-link-custom"
              onClick={handleCloseSidebar}
            >
              <FontAwesomeIcon icon={faChartLine} className="me-2 fa-icon" />
              Leads
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/admin/rate-sheet"
              className="d-flex align-items-center nav-link-custom"
              onClick={handleCloseSidebar}
              >
              <FontAwesomeIcon icon={faDollarSign} className="me-2 fa-icon" />
              Rate Sheet
            </Nav.Link>
            </Nav>
            
{user && (
  <div
    className="sidebar-profile d-flex align-items-center"
    onClick={handleProfileClick}
  >
    <div className="profile-image-container">
      <img
        src={user.photoURL || '/default-profile.png'}
        alt={user.displayName}
        className="sidebar-profile-img"
      />
    </div>
    <p className="sidebar-profile-name mb-0 ms-2">{user.displayName}</p>
  </div>
)}
</Container>
</Navbar>
</div>
{/* BACKDROP OVERLAY: Only render if sidebar is visible */}
{showSidebar && (
        <div
          className="sidebar-overlay"
          onClick={() => setShowSidebar(false)}
        />
      )}
</>
);
};

export default Sidebar;