import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import logo from '../images/logo.webp'; // Ensure correct path to logo

const generatePDF = async (invoice) => {
  if (!invoice) {
    console.error("Invoice data is missing:", invoice);
    return;
  }

  console.log("Invoice Data Passed to generatePDF:", invoice);

  const doc = new jsPDF();
  const orangeColor = '#D77124';
  const margin = 20;

  const totalCharges = invoice.invoiceAmount ? parseFloat(invoice.invoiceAmount).toFixed(2) : "0.00";
  const timesheets = Array.isArray(invoice.timesheets) ? invoice.timesheets : [];

  if (!invoice.timesheets || invoice.timesheets.length === 0) {
    console.error("Error: No timesheets found for this invoice", invoice.timesheets);
    return;
  }

 // Extract all dates and sort them as actual Date objects
  const timesheetDates = invoice.timesheets
    .map(entry => entry.date) // Keep as string (YYYY-MM-DD)
    .sort(); // Sort lexicographically (works since it's YYYY-MM-DD)

  // Get the start and end dates directly from the sorted list
  const billingStartDate = timesheetDates[0]; // First date as string (already "YYYY-MM-DD")
  const billingEndDate = timesheetDates[timesheetDates.length - 1]; // Last date as string  

  console.log("Billing Start Date:", billingStartDate);
  console.log("Billing End Date:", billingEndDate);


  const filteredTimesheets = invoice.timesheets.filter(entry =>
    entry.date >= billingStartDate && entry.date <= billingEndDate
  );


  // Logo and Header Section
  const logoWidth = 50;
  const logoHeight = 25;
  doc.addImage(logo, 'WEBP', margin, 10, logoWidth, logoHeight);
  doc.setFontSize(16);
  doc.setTextColor(orangeColor);
  doc.text('Imagine Seniorcare Inc.', margin + 60, 15);
  doc.setFontSize(11);
  doc.setTextColor(0, 0, 0);
  doc.text('Email: imaginescinc@gmail.com | Phone: (831)-332-5053', margin + 60, 22);
  doc.text('Website: imagineseniorcareinc.com', margin + 60, 29);
  doc.text('255 El Camino Real #204, Burlingame, CA 94010', margin + 60, 36);

  // Divider Line
  doc.setDrawColor(orangeColor);
  doc.setLineWidth(0.5);
  doc.line(margin, 42, doc.internal.pageSize.width - margin, 42);

  // Invoice Details
  doc.setFontSize(20);
  doc.setTextColor(orangeColor);
  doc.text('Invoice', margin, 55);

  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);
  const invoiceDetailsTop = 65;
  doc.text(`Invoice Number: ${invoice.invoiceNumber || 'N/A'}`, margin, invoiceDetailsTop);
  doc.text(`Date: ${invoice.createdAt ? new Date(invoice.createdAt).toLocaleDateString() : "N/A"}`, margin, invoiceDetailsTop + 8);
  doc.text(`Client Name: ${invoice.clientName || 'N/A'}`, margin, invoiceDetailsTop + 16);
  doc.text(`Client Address: ${invoice.clientAddress || 'N/A'}`, margin, invoiceDetailsTop + 24);
  doc.text(`Service Period: ${billingStartDate} - ${billingEndDate}`, margin, invoiceDetailsTop + 32);

  // Generate Table Data
  const tableTop = invoiceDetailsTop + 50;
  const tableBody = filteredTimesheets.map(entry => [
    new Intl.DateTimeFormat("en-US", { 
      timeZone: "America/Los_Angeles", 
      year: "numeric", 
      month: "2-digit", 
      day: "2-digit" 
    }).format(new Date(new Date(entry.date).getTime() + 24 * 60 * 60 * 1000)),    
    entry.totalHours ? `${entry.totalHours} hrs` : "0 hrs",
    entry.hourlyRate ? `$${parseFloat(entry.hourlyRate).toFixed(2)}` : "$0.00",
    entry.totalCharges ? `$${parseFloat(entry.totalCharges || 0).toFixed(2)}` : "$0.00"
  ]);

  // Add Grand Total Row
  tableBody.push([
    { content: 'Total Invoice Amount:', colSpan: 3, styles: { halign: 'right', fontStyle: 'bold', fontSize: 13 } },
    { content: `$${totalCharges}`, styles: { fontStyle: 'bold', fontSize: 13 } }
  ]);

  // Create Table
  autoTable(doc, {
    startY: tableTop,
    head: [['Date', 'Hours', 'Hourly Rate', 'Total']],
    body: tableBody,
    theme: 'grid',
    headStyles: { fillColor: orangeColor, textColor: [255, 255, 255] },
  });

  // Terms & Conditions Section
  const termsTop = doc.lastAutoTable.finalY + 20;
  doc.setFontSize(16);
  doc.setTextColor(orangeColor);
  doc.text('Terms & Conditions', margin, termsTop);
  doc.setFontSize(11);
  doc.setTextColor(0, 0, 0);
  doc.text('• Payment for the invoice must be made upon receiving this invoice.', margin, termsTop + 8);
  doc.text('• Please make all checks payable to Imagine Seniorcare Incorporated.', margin, termsTop + 14);

  // Save the PDF
  doc.save(`Invoice_${invoice.clientName}.pdf`);
};

export default generatePDF;
