import React, { useState, useEffect, useRef } from 'react';
import { Modal, Form, Button, Row, Col, Spinner } from 'react-bootstrap';
import { addDoc, collection, doc, runTransaction, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { toast } from 'react-toastify';

const CreateInvoice = ({ 
  show, 
  onClose, 
  clientName, 
  clientAddress, 
  stayDuration, 
  invoiceAmount, // ✅ Receive the total invoice amount
  totalHours, 
  hourlyRate, 
  timesheets 
  }) => {
  
  const [description, setDescription] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [priceBreakdown, setPriceBreakdown] = useState([]);
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(0);
  const invoiceGenerated = useRef(false); // Prevent duplicate calls
  const [filteredStartDate, setFilteredStartDate] = useState('');
  const [filteredEndDate, setFilteredEndDate] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false); // ✅ Prevent multiple clicks

  // Ensure totalInvoiceAmount is updated when invoiceAmount is received
  useEffect(() => {
    setTotalInvoiceAmount(invoiceAmount || 0); // ✅ Default to 0 if undefined
  }, [invoiceAmount]);

  useEffect(() => {
    if (stayDuration?.dates && Array.isArray(stayDuration.dates)) {
      const formattedBreakdown = stayDuration.dates.map((entry) => ({
        date: entry.date && !isNaN(new Date(entry.date).getTime()) 
          ? new Date(entry.date).toISOString().split('T')[0] 
          : "Invalid Date",
        totalCharges: parseFloat(entry.totalCharges) || 0, // Ensure totalCharges is always a number
      }));
  
      setPriceBreakdown(formattedBreakdown);
      setTotalInvoiceAmount(formattedBreakdown.reduce((sum, entry) => sum + entry.totalCharges, 0));
    }
  }, [stayDuration], [setPriceBreakdown]);
  
  useEffect(() => {
    if (timesheets.length > 0) {
      const validDates = timesheets
        .map((entry) => new Date(entry.date))
        .filter(date => !isNaN(date.getTime())) // Filter out invalid dates
  
      if (validDates.length > 0) {
        const minDate = new Date(Math.min(...validDates)).toISOString().split('T')[0];
        const maxDate = new Date(Math.max(...validDates)).toISOString().split('T')[0];
  
        setFilteredStartDate(minDate);
        setFilteredEndDate(maxDate);
      }
    }
  }, [timesheets]); // Run when timesheets change

  const handleSaveInvoice = async () => {
    if (!clientName || isNaN(invoiceAmount)) {
        toast.error('Please enter valid details including invoice amount.');
        return;
    }

    try {
        setIsSaving(true); // ✅ Disable button

        // Get today's date in MM-DD-YYYY format
        const today = new Date();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Ensure 2-digit month
        const day = String(today.getDate()).padStart(2, '0'); // Ensure 2-digit day
        const year = today.getFullYear();
        const formattedDate = `${month}-${day}-${year}`; // "01-31-2025"

        // Reference Firestore document for tracking invoice numbers
        const invoiceCounterRef = doc(db, 'settings', 'invoiceCounter');

        // Fetch current invoice number and last updated date
        const invoiceCounterSnap = await getDoc(invoiceCounterRef);
        let invoiceNumberSuffix = 1000;

        if (invoiceCounterSnap.exists()) {
            const data = invoiceCounterSnap.data();
            if (data.lastUpdated === formattedDate) {
                invoiceNumberSuffix = data.currentNumber + 1; // Increment if same day
            } else {
                invoiceNumberSuffix = 1000; // Reset if new day
            }
        }

        // ✅ Generate final invoice number in MM-DD-YYYY-XXXX format
        const invoiceNumber = `${formattedDate}-${String(invoiceNumberSuffix).padStart(4, '0')}`;

        console.log(`🚀 Saving Invoice #${invoiceNumber}`);

        const newInvoice = {
            clientName: clientName.trim(),
            clientAddress: clientAddress.trim(),
            stayDuration: {
                start: stayDuration.start ? new Date(stayDuration.start).toISOString() : null,
                end: stayDuration.end ? new Date(stayDuration.end).toISOString() : null,
            },
            invoiceAmount: parseFloat(invoiceAmount),
            invoiceNumber, // ✅ Ensure correct invoice number format
            createdAt: today.toISOString(),
            description: description.trim() || 'Services Rendered',
            status: 'Unpaid',
            timesheets: timesheets.length > 0 ? timesheets : [],
        };

        // 🔥 Save the invoice in Firestore
        await addDoc(collection(db, 'invoices'), newInvoice);

        // 🔄 Update the invoice counter in Firestore
        await setDoc(invoiceCounterRef, {
            currentNumber: invoiceNumberSuffix,
            lastUpdated: formattedDate
        });

        // ✅ Show success message
        console.log(`✅ Invoice #${invoiceNumber} created successfully!`);
        toast.success(`Invoice #${invoiceNumber} created successfully! ✅`, {
          position: "top-right",
          autoClose: 3000, // 3-second auto close
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Close the modal
        onClose();
        
    } catch (error) {
        console.error('❌ Error saving invoice:', error);
        toast.error('Failed to save invoice.');
    }
  };



  return (
    <Modal size="lg" show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create New Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="clientName">
                <Form.Label>Client's Name</Form.Label>
                <Form.Control type="text" value={clientName} readOnly />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="clientAddress">
                <Form.Label>Client's Address</Form.Label>
                <Form.Control type="text" value={clientAddress} readOnly />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
          <Col>
            <Form.Group controlId="stayStart">
              <Form.Label>Start Date</Form.Label>
              <Form.Control type="date" value={filteredStartDate || ''} readOnly />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="stayEnd">
              <Form.Label>End Date</Form.Label>
              <Form.Control type="date" value={filteredEndDate || ''} readOnly />
            </Form.Group>
          </Col>
        </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="invoiceAmount">
                <Form.Label>Total Invoice Amount</Form.Label>
                <Form.Control type="text" value={`$${totalInvoiceAmount.toFixed(2)}`} readOnly />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="priceBreakdown">
                <Form.Label>Price Breakdown (Per Day)</Form.Label>
                <div style={{ maxHeight: '200px', overflowY: 'auto', border: '1px solid #ccc', padding: '10px' }}>
                  <table style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr style={{ backgroundColor: '#D77124', color: 'white' }}>
                        <th style={{ padding: '8px' }}>Date</th>
                        <th style={{ padding: '8px' }}>Hours</th>
                        <th style={{ padding: '8px' }}>Hourly Rate</th>
                        <th style={{ padding: '8px' }}>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {timesheets.length > 0 ? (
                        timesheets.map((entry, index) => {
                          const dateObj = new Date(entry.date + "T00:00:00-08:00"); // Explicitly set PST time zone

                          const formattedDate = entry.date && !isNaN(dateObj)
                            ? dateObj.toLocaleDateString("en-US", { timeZone: "America/Los_Angeles" })
                            : "N/A"; 

                          return (
                            <tr key={index}>
                              <td style={{ padding: '8px' }}>{formattedDate}</td>
                              <td style={{ padding: '8px' }}>{entry.totalHours} hrs</td>
                              <td style={{ padding: '8px' }}>${entry.hourlyRate ? entry.hourlyRate.toFixed(2) : "0.00"}</td>
                              <td style={{ padding: '8px' }}>${parseFloat(entry.totalCharges || 0).toFixed(2)}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="4" style={{ padding: '8px', textAlign: 'center' }}>No data available.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter description for services rendered"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="totalHours">
                <Form.Label>Total Hours of Stay</Form.Label>
                <Form.Control type="number" value={totalHours} readOnly />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} disabled={isSaving}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSaveInvoice} disabled={isSaving}>
          {isSaving ? (
            <>
              <Spinner animation="border" size="sm" /> Creating...
            </>
          ) : (
            "Create Invoice"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateInvoice;
