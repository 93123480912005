import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase';

const AddLeadModal = ({ show, handleClose, onLeadAdded }) => {
  const [newLead, setNewLead] = useState({
    name: '',
    date: new Date(),
    contactInfo: '',
    contactPerson: '',
    address: '',
    referralSource: '',
    marketer: '',
    startOfCare: null,
    locationType: 'Private Home',
    status: 'Pending',
  });

  const handleChange = (e) => {
    setNewLead({ ...newLead, [e.target.name]: e.target.value });
  };

  const handleDateChange = (field, date) => {
    setNewLead({ ...newLead, [field]: date });
  };

  const handleSave = async () => {
    try {
      const docRef = await addDoc(collection(db, 'leads'), newLead);
      onLeadAdded({ id: docRef.id, ...newLead });
      handleClose();
    } catch (error) {
      console.error('Error adding lead:', error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add New Lead</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* First Row */}
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Lead Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={newLead.name}
                  onChange={handleChange}
                  placeholder="Enter lead name"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Contact Person</Form.Label>
                <Form.Control
                  type="text"
                  name="contactPerson"
                  value={newLead.contactPerson}
                  onChange={handleChange}
                  placeholder="Enter contact person"
                />
              </Form.Group>
            </Col>
          </Row>

          {/* Second Row */}
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Contact Info</Form.Label>
                <Form.Control
                  type="text"
                  name="contactInfo"
                  value={newLead.contactInfo}
                  onChange={handleChange}
                  placeholder="Enter contact info"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Referral Source</Form.Label>
                <Form.Control
                  type="text"
                  name="referralSource"
                  value={newLead.referralSource}
                  onChange={handleChange}
                  placeholder="Enter referral source"
                />
              </Form.Group>
            </Col>
          </Row>

          {/* Third Row */}
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Date</Form.Label>
                <DatePicker
                  selected={newLead.date}
                  onChange={(date) => handleDateChange('date', date)}
                  dateFormat="MM/dd/yyyy"
                  className="form-control"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Start of Care</Form.Label>
                <DatePicker
                  selected={newLead.startOfCare}
                  onChange={(date) => handleDateChange('startOfCare', date)}
                  dateFormat="MM/dd/yyyy"
                  className="form-control"
                  placeholderText="Select a date"
                />
              </Form.Group>
            </Col>
          </Row>

          {/* Fourth Row */}
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Marketer</Form.Label>
                <Form.Control
                  type="text"
                  name="marketer"
                  value={newLead.marketer}
                  onChange={handleChange}
                  placeholder="Enter marketer name"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  value={newLead.address}
                  onChange={handleChange}
                  placeholder="Enter address"
                />
              </Form.Group>
            </Col>
          </Row>

          {/* Fifth Row */}
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Location Type</Form.Label>
                <Form.Select
                  name="locationType"
                  value={newLead.locationType}
                  onChange={handleChange}
                >
                  <option value="Private Home">Private Home</option>
                  <option value="Assisted Living">Assisted Living</option>
                  <option value="Nursing Home">Nursing Home</option>
                  <option value="Hospital">Hospital</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Form.Select name="status" value={newLead.status} onChange={handleChange}>
                  <option value="Pending">Pending</option>
                  <option value="Contacted">Contacted</option>
                  <option value="Qualified">Qualified</option>
                  <option value="Converted">Converted</option>
                  <option value="Lost">Lost</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save Lead
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddLeadModal;
