import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Table,
  InputGroup,
  FormControl,
  Spinner,
} from 'react-bootstrap';
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  addDoc,
  deleteDoc,
  query,
  where
} from 'firebase/firestore';
import { db } from '../firebase';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faList,
  faThLarge,
  faFilter,
  faSearch,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify'; // Importing toast and ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import Toast CSS
import '../styles/Dashboard.css';

const Dashboard = () => {
  const [clients, setClients] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const [showEditClientModal, setShowEditClientModal] = useState(false);
  const [clientStayStart, setClientStayStart] = useState(new Date());
  const [clientStayEnd, setClientStayEnd] = useState(new Date());
  const [newClientName, setNewClientName] = useState('');
  const [newClientPhone, setNewClientPhone] = useState('');
  const [newClientEmail, setNewClientEmail] = useState('');
  const [newClientAddress, setNewClientAddress] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientHourlyRate, setHourlyRate] = useState(''); // Set hourly rate state
  const [totalHours, setTotalHours] = useState(''); // Set total hours state
  const [viewType, setViewType] = useState('row');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('');
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [totalDaysStay, setTotalDaysStay] = useState(''); // New state to track the total days of stay
  const [marketerName, setMarketerName] = useState(''); // New state for marketer name
  const [marketerCommission, setMarketerCommission] = useState(0); // New state for marketer commission
  const [clientStatus, setClientStatus] = useState('Active'); // Default value
  const [statusFilter, setStatusFilter] = useState("all");


  useEffect(() => {
    if (window.innerWidth < 768) {
      setViewType('card');
    }
  }, []);
  

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [clientsSnapshot, employeesSnapshot] = await Promise.all([
          getDocs(collection(db, 'clients')),
          getDocs(collection(db, 'employees')),
        ]);
  
        const clientsData = clientsSnapshot.docs.map((doc) => {
          const client = doc.data();
          const startDate = client.stayStart ? new Date(client.stayStart) : null;
          const endDate = client.stayEnd ? new Date(client.stayEnd) : null;
  
          return {
            id: doc.id,
            ...client,
            stayStart: startDate,
            stayEnd: endDate,
            status: client.status || 'Active', // Default to 'Active' if not set
          };
        });
  
        setClients(clientsData);
  
        const employeesData = employeesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
  
        setEmployees(employeesData);
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Failed to fetch data. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  

  const fetchClients = async () => {
    try {
        const clientSnapshot = await getDocs(collection(db, 'clients'));
        const clientsData = clientSnapshot.docs.map((doc) => {
            const client = doc.data();

            return {
                id: doc.id,
                ...client,
                stayStart: client.stayStart ? new Date(client.stayStart) : null, // Convert to Date
                stayEnd: client.stayEnd ? new Date(client.stayEnd) : null, // Convert to Date
                status: client.status || 'Active' // Default to 'Active' if not set
            };
        });

        setClients(clientsData);
    } catch (error) {
        console.error('❌ Error fetching clients:', error);
        toast.error('Failed to fetch clients.');
    }
  };

  
  const handleEditClient = (client) => {
    setSelectedClient(client);
    setNewClientName(client.name);
    setNewClientPhone(client.phone);
    setNewClientEmail(client.email);
    setNewClientAddress(client.address);
    setClientStayStart(client.stayStart ? new Date(client.stayStart) : new Date());
    setClientStayEnd(client.stayEnd ? new Date(client.stayEnd) : new Date());
    setSelectedEmployee(
      employees.find((emp) => emp.name === client.assignedEmployee) || null
    );
    setHourlyRate(client.hourlyRate);
    setTotalHours(client.totalHours);
    setMarketerName(client.marketerName || ""); 
    setClientStatus(client.status || 'Active'); // Set status
    setShowEditClientModal(true);
  };  

  const handleSaveEditClient = async () => {
    if (!selectedClient) return;
  
    try {
      const clientRef = doc(db, "clients", selectedClient.id);
  
      await updateDoc(clientRef, {
        name: newClientName,
        phone: newClientPhone,
        email: newClientEmail,
        address: newClientAddress,
        stayStart: clientStayStart.toISOString(),
        stayEnd: clientStayEnd.toISOString(),
        assignedEmployee: selectedEmployee ? selectedEmployee.name : "No Employee Assigned",
        marketerName: marketerName || "None",
        status: selectedClient.status, // Ensure status is updated
      });      
  
      toast.success("Client updated successfully!");
      setShowEditClientModal(false);
      fetchClients();
    } catch (error) {
      console.error("Error updating client:", error);
      toast.error("Failed to update client.");
    }
  };  

  const handleAddClient = () => {
    setNewClientName('');
    setNewClientPhone('');
    setNewClientEmail('');
    setNewClientAddress('');
    setClientStayStart(new Date());
    setClientStayEnd(new Date());
    setSelectedEmployee(null);
    setShowAddClientModal(true);
  };

  const handleSaveNewClient = async () => {
    if (!newClientName || !newClientPhone || !newClientEmail || !newClientAddress || !clientStayStart || !clientStayEnd) {
        toast.error("Please fill out all required fields");
        return;
    }

    try {
        // Create client first
        const clientRef = await addDoc(collection(db, "clients"), {
            name: newClientName,
            phone: newClientPhone,
            email: newClientEmail,
            address: newClientAddress,
            stayStart: clientStayStart.toISOString(),
            stayEnd: clientStayEnd.toISOString(),
            hourlyRate: parseFloat(clientHourlyRate), // Save base hourly rate
            totalHours: parseFloat(totalHours), // Save base total hours
            assignedEmployee: selectedEmployee ? selectedEmployee.name : "No Employee Assigned",
            marketer: marketerName || "None",
            marketerCommission: 0,
            status: "Active",
        });
        

        console.log("New client created with ID:", clientRef.id);

        // Generate timesheets
        let currentDate = new Date(clientStayStart);
        currentDate.setHours(0, 0, 0, 0);
        const endDate = new Date(clientStayEnd);
        endDate.setHours(0, 0, 0, 0);

        const batch = [];
        while (currentDate <= endDate) {
            batch.push({
                clientId: clientRef.id,
                clientName: newClientName,
                assignedEmployee: selectedEmployee ? selectedEmployee.name : "Unassigned",
                logDate: currentDate.toISOString().split("T")[0],
                totalHours: parseFloat(totalHours), // Set base hours per day
                hourlyRate: parseFloat(clientHourlyRate), // Set base hourly rate
                marketer: marketerName || "None", // ✅ Ensure marketer name is saved in timesheets
                marketerCommission: 0, // Placeholder, updated later
            });

            currentDate.setDate(currentDate.getDate() + 1);
        }

        console.log(`Creating ${batch.length} timesheets for new client...`);

        const timesheetsCollection = collection(db, "clientTimesheets");
        const batchPromises = batch.map(timesheet => addDoc(timesheetsCollection, timesheet));
        await Promise.all(batchPromises);

        // Now calculate commission
        const commission = await calculateCommissionFromDatabase(clientRef.id);

        // Update client with actual commission
        await updateDoc(clientRef, { marketerCommission: commission });

        toast.success("Client and timesheets added successfully!");
        setShowAddClientModal(false);
        fetchClients();
    } catch (error) {
        console.error("Error adding client:", error);
        toast.error("Failed to add client.");
    }
  };



  const calculateCommissionFromDatabase = async (clientId) => {
    try {
        const timesheetsQuery = query(
            collection(db, "clientTimesheets"),
            where("clientId", "==", clientId)
        );

        const timesheetsSnapshot = await getDocs(timesheetsQuery);
        const timesheets = timesheetsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        if (timesheets.length === 0) return 0; // No data yet

        // Group charges and net income by month
        const monthlyData = {};
        timesheets.forEach(ts => {
            const month = ts.logDate.slice(0, 7); // YYYY-MM format
            if (!monthlyData[month]) {
                monthlyData[month] = { totalCharges: 0, netIncome: 0 };
            }
            monthlyData[month].totalCharges += parseFloat(ts.totalCharges || 0);
            monthlyData[month].netIncome += parseFloat(ts.netIncome || 0);
        });

        // Sort months and determine first month
        const months = Object.keys(monthlyData).sort();
        let totalCommission = 0;

        months.forEach((month, index) => {
            let commission = 0;
            if (index === 0) {
                // First month → 10% of total charges
                commission = monthlyData[month].totalCharges * 0.10;
            } else {
                // Subsequent months → 10% of net income
                commission = monthlyData[month].netIncome * 0.10;
            }
            totalCommission += commission;

            // Update commission in database for that month
            timesheets
                .filter(ts => ts.logDate.startsWith(month))
                .forEach(async (ts) => {
                    await updateDoc(doc(db, "clientTimesheets", ts.id), {
                        marketerCommission: commission.toFixed(2),
                    });
                });
        });

        return totalCommission.toFixed(2);
    } catch (error) {
        console.error("Error calculating commission:", error);
        return 0;
    }
  };
  
  const handleShowDeleteConfirm = (client) => {
    setClientToDelete(client);
    setShowDeleteConfirmModal(true);
  };

  const handleConfirmDelete = async () => {
    if (clientToDelete) {
        try {
            const clientId = clientToDelete.id;

            // 🔹 1. Get all timesheets that belong to this client
            const timesheetsQuery = query(collection(db, "clientTimesheets"), where("clientId", "==", clientId));
            const timesheetsSnapshot = await getDocs(timesheetsQuery);

            // 🔹 2. Delete all timesheets associated with the client
            const deletePromises = timesheetsSnapshot.docs.map(ts => deleteDoc(doc(db, "clientTimesheets", ts.id)));
            await Promise.all(deletePromises);

            console.log(`Deleted ${timesheetsSnapshot.size} timesheets for client ${clientId}`);

            // 🔹 3. Delete the client document
            const clientRef = doc(db, "clients", clientId);
            await deleteDoc(clientRef);

            // 🔹 4. Update state and UI
            setClients(prevClients => prevClients.filter(client => client.id !== clientToDelete.id));
            setShowDeleteConfirmModal(false);

            toast.success("Client and their timesheets deleted successfully!");
        } catch (error) {
            console.error("Error deleting client and timesheets:", error);
            toast.error("Failed to delete client and associated timesheets.");
        }
    }
  };

  const getSortedClients = () => {
    let sortedClients = [...clients];
  
    if (sortOption === "name") {
      sortedClients.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortOption === "uploadDate") {
      sortedClients.sort((a, b) => new Date(b.stayStart) - new Date(a.stayStart));
    }
  
    // Apply Status Filter
    if (statusFilter !== "all") {
      sortedClients = sortedClients.filter(client => client.status === statusFilter);
    }
  
    return sortedClients;
  };
  

  const handleViewSwitch = (view) => {
    setViewType(view);
  };

  const renderClientRows = () => {
    const filteredClients = getSortedClients().filter((client) =>
      client.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    if (filteredClients.length === 0) {
      return (
        <tr>
          <td colSpan="7" className="text-center no-clients-message">
            No clients found.
          </td>
        </tr>
      );
    }
  
    return filteredClients.map((client) => {
      const startDateString = client.stayStart
        ? client.stayStart.toLocaleDateString()
        : 'N/A';
      const endDateString = client.stayEnd
        ? client.stayEnd.toLocaleDateString()
        : 'N/A';
  
      return (
        <tr key={client.id}>
          <td onClick={() => handleEditClient(client)}>{client.name}</td>
          <td onClick={() => handleEditClient(client)}>{client.phone || 'N/A'}</td>
          <td onClick={() => handleEditClient(client)}>{client.email}</td>
          <td onClick={() => handleEditClient(client)}>{client.assignedEmployee || 'None'}</td>
          <td onClick={() => handleEditClient(client)}>{`${startDateString} - ${endDateString}`}</td>
          <td>
            <Form.Select
              value={client.status || 'Active'}
              onChange={async (e) => {
                const newStatus = e.target.value;
                try {
                  await updateDoc(doc(db, "clients", client.id), { status: newStatus });
                  toast.success("Client status updated successfully!");
                  setClients(prevClients =>
                    prevClients.map(c =>
                      c.id === client.id ? { ...c, status: newStatus } : c
                    )
                  );
                } catch (error) {
                  console.error("Error updating client status:", error);
                  toast.error("Failed to update status.");
                }
              }}
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
              <option value="Completed">Completed</option>
            </Form.Select>
          </td>
          <td className="text-center">
            <Button
              size="md"
              className="me-2 internal-delete-buttons"
              variant="danger"
              onClick={(e) => {
                e.stopPropagation();
                handleShowDeleteConfirm(client);
              }}
              title="Delete Client"
            >
              <FontAwesomeIcon icon={faTrash} color="white" />
              <span className="ml-2 square-button">Delete</span>
            </Button>
          </td>
        </tr>
      );
    });
  };
  
  
  const handleStatusChange = async (clientId, newStatus) => {
    try {
      const clientRef = doc(db, "clients", clientId);
      await updateDoc(clientRef, { status: newStatus });
  
      setClients((prevClients) =>
        prevClients.map((client) =>
          client.id === clientId ? { ...client, status: newStatus } : client
        )
      );
  
      toast.success("Client status updated!");
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Failed to update status.");
    }
  };
  

  const calculateTotalDaysStay = (startDate, endDate) => {
    if (startDate && endDate) {
      const diffTime = Math.abs(endDate - startDate); // Difference in milliseconds
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert to days
      setTotalDaysStay(diffDays); // Update state
      setTotalHours(diffDays); // Optionally update total hours if needed
    } else {
      setTotalDaysStay('');
    }
  };
  
  const renderClientCards = () => {
    const filteredClients = getSortedClients().filter((client) =>
      client.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (filteredClients.length === 0) {
      return (
        <Col className="text-center w-100">
          <p className="no-clients-message">No clients found.</p>
        </Col>
      );
    }

    return filteredClients.map((client) => {
      const startDateString = client.stayStart
        ? client.stayStart.toLocaleDateString()
        : 'N/A';
      const endDateString = client.stayEnd
        ? client.stayEnd.toLocaleDateString()
        : 'N/A';

      return (
        <Col key={client.id} md={4} className="mb-4">
          <div
            className="dashboard-client-container"
            style={{ cursor: 'pointer' }}
            onClick={() => handleEditClient(client)}
          >
            <h3>{client.name}</h3>
            <hr className="divider-card" />
            <div>
              <p className="client-info-title">Phone Number</p>
              <p className="client-info-content">
                {client.phone || 'No phone number available'}
              </p>
            </div>
            <div className="mt-2">
              <p className="client-info-title">Email</p>
              <p className="client-info-content">{client.email}</p>
            </div>
            <div className="mt-2">
              <p className="client-info-title">Assigned Employee</p>
              <p className="client-info-content">
                {client.assignedEmployee || 'None'}
              </p>
            </div>
            <div className="mt-2">
              <p className="client-info-title">Duration of Stay</p>
              <p className="client-info-content">
                {`${startDateString} - ${endDateString}`}
              </p>
            </div>
            <hr className="divider-card" />

            <div className="mt-4 d-flex justify-content-between">
              <Button
                variant="danger"
                size="md" // Match button size to invoice table
                className='me-2 internal-delete-buttons'
                onClick={(e) => {
                  e.stopPropagation();
                  handleShowDeleteConfirm(client);
                }}
                title="Delete Client"
              >
                <FontAwesomeIcon icon={faTrash} color="white" className='mr-2' />
                Delete
              </Button>
            </div>
          </div>
        </Col>
      );
    });
  };

  const renderRowView = () => (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th style={{width: "150px"}}>Client Name</th>
          <th>Phone Number</th>
          <th>Email</th>
          <th >Assigned Employee</th>
          <th style={{width: "120px"}}>Duration of Stay</th>
          <th style={{ width: "160px" }}>Status</th> {/* Increase width */}
          <th style={{ width: "140px" }}>Actions</th> {/* Reduce width */}
        </tr>
      </thead>
      <tbody>{renderClientRows()}</tbody>
    </Table>
  );

  const renderCardView = () => (
    <Row>
      {renderClientCards()}
    </Row>
  );

  const renderContent = () => {
    if (isLoading) {
      return (
        <Row className="justify-content-center">
          <Col className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      );
    }

    if (viewType === 'row') {
      return renderRowView();
    } else if (viewType === 'card') {
      return renderCardView();
    }
  };

  return (
    <Container fluid className="timecard-container">
      {/* Header Section */}
      <Row className="align-items-center mb-3">
        <Col>
          <h1 className="timecard-title">Client Dashboard</h1>
        </Col>
        <Col className="text-end">
          <Button className="dashboard-add-client-btn" onClick={handleAddClient}>
            + Add Client
          </Button>
        </Col>
      </Row>

      <hr className="divider" />

      {/* Search and View Controls */}
      <Row className="mb-4 align-items-center">
      <Col xs={12} md={4} className="mb-2 mb-md-0">
        <InputGroup>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
          <FormControl
            type="text"
            placeholder="Search by client name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>
      </Col>

      <Col xs={12} md={4} className="d-flex justify-content-md-end align-items-center flex-wrap mt-2 mt-md-0">
          <Button
            variant="light"
            className={`view-icon-button ms-0 ms-md-2 mb-2 mb-md-0 ${viewType === 'row' ? 'active' : ''}`}
            onClick={() => handleViewSwitch('row')}
          >
            <FontAwesomeIcon icon={faList} className="me-2" />
            Row View
          </Button>

          <Button
            variant="light"
            className={`view-icon-button ms-0 ms-md-2 mb-2 mb-md-0 ${viewType === 'card' ? 'active' : ''}`}
            onClick={() => handleViewSwitch('card')}
          >
            <FontAwesomeIcon icon={faThLarge} className="me-2" />
            Card View
          </Button>
      </Col>
      <Col xs={12} md={2} className="mt-2 mt-md-0">
        <InputGroup>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faFilter} />
          </InputGroup.Text>
          <Form.Select
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
          >
            <option value="">Default Filter</option>
            <option value="name">Name (A-Z)</option>
            <option value="uploadDate">Log Date (Newest First)</option>
          </Form.Select>
        </InputGroup>
      </Col>

        <Col md={2}>
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faFilter} />
            </InputGroup.Text>
            <Form.Select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="all">All Statuses</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
              <option value="Completed">Completed</option>
            </Form.Select>
          </InputGroup>
        </Col>
      </Row>

      {/* Client List */}
      {renderContent()}

      {/* Add Client Modal */}
      <Modal size="lg" show={showAddClientModal} onHide={() => setShowAddClientModal(false)}>
        <Modal.Header className="employee-log-modal-header" closeButton>
          <Modal.Title className="employee-log-modal-title">Add New Client</Modal.Title>
        </Modal.Header>
        <Modal.Body className="employee-log-modal-body">
          <Form className="employee-log-form">
            <Form.Group controlId="formClientName">
              <Form.Label>Client Name</Form.Label>
              <Form.Control
                className='all-modal-form-control'
                type="text"
                value={newClientName}
                onChange={(e) => setNewClientName(e.target.value)}
                placeholder="Enter client's name"
              />
            </Form.Group>
            <Form.Group controlId="formClientPhone" className="mt-3">
              <Form.Label>Client Phone Number</Form.Label>
              <Form.Control
                className='all-modal-form-control'
                type="text"
                value={newClientPhone}
                onChange={(e) => setNewClientPhone(e.target.value)}
                placeholder="Enter client's phone number"
              />
            </Form.Group>
            <Form.Group controlId="formClientEmail" className="mt-3">
              <Form.Label>Client Email</Form.Label>
              <Form.Control
                className='all-modal-form-control'
                type="email"
                value={newClientEmail}
                onChange={(e) => setNewClientEmail(e.target.value)}
                placeholder="Enter client's email"
              />
            </Form.Group>
            <Form.Group controlId="formClientAddress" className="mt-3">
              <Form.Label>Client Address</Form.Label>
              <Form.Control
                className='all-modal-form-control'
                type="address"
                value={newClientAddress}
                onChange={(e) => setNewClientAddress(e.target.value)}
                placeholder="Enter client's address"
              />
            </Form.Group>
            <Form.Group controlId="formAssignedEmployee" className="mt-3">
              <Form.Label>Assign Employee</Form.Label>
              <Form.Select
                value={selectedEmployee ? selectedEmployee.id : ''}
                onChange={(e) =>
                  setSelectedEmployee(
                    employees.find((emp) => emp.id === e.target.value) || null
                  )
                }
              >
                <option value="">None</option>
                {employees.map((employee) => (
                  <option key={employee.id} value={employee.id}>
                    {employee.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formClientStay" className="mt-4">
              <Form.Label className='stay-date'>Stay Duration</Form.Label>
              <div className="d-flex justify-content-between">
                <div>
                  <Form.Label>Start Date</Form.Label>
                  <DatePicker
                    selected={clientStayStart}
                    className='all-modal-form-control'
                    onChange={(date) => {
                      setClientStayStart(date);
                      calculateTotalDaysStay(date, clientStayEnd); // Recalculate total days
                    }}
                    dateFormat="MM/dd/yyyy"
                  />
                </div>
                <div>
                  <Form.Label>End Date</Form.Label>
                  <DatePicker
                    selected={clientStayEnd}
                    className='all-modal-form-control'
                    onChange={(date) => {
                      setClientStayEnd(date);
                      calculateTotalDaysStay(clientStayStart, date); // Recalculate total days
                    }}
                    dateFormat="MM/dd/yyyy"
                  />
                </div>
              </div>
            </Form.Group>
             {/* Hourly Rate Input */}
             <Form.Group controlId="formClientRates" className="mt-3">
            <Row>
              <Col md={6}>
                <Form.Label>Hourly Rate</Form.Label>
                <Form.Control
                  type="number"
                  className="all-modal-form-control"
                  value={clientHourlyRate}
                  onChange={(e) => setHourlyRate(e.target.value)}
                  placeholder="Enter client's hourly rate"
                />
              </Col>
              <Col md={6}>
                <Form.Label>Total Hours Per Day</Form.Label>
                <Form.Control
                  type="number"
                  className="all-modal-form-control"
                  onChange={(e) => setTotalHours(e.target.value)}
                  placeholder="Enter total hours per day"
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group controlId="formEditClientMarketer" className="mt-4">
              <Form.Label className='stay-date'>Marketer Information</Form.Label>
              <div className="d-flex justify-content-between">
                <div>
                  <Form.Label>Marketer</Form.Label>
                  <Form.Control
                   type="text"
                   className='all-modal-form-control'
                   value={marketerName}
                   onChange={(e) => setMarketerName(e.target.value)}
                   placeholder="Enter marketer's name"
                 />
                </div>
                <div>
                  <Form.Label>Marketer Commision</Form.Label>
                  <Form.Control
                    type="number"
                    className="all-modal-form-control"    
                    readOnly              />
                </div>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="employee-log-modal-footer">
          <Button variant="secondary" onClick={() => setShowAddClientModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveNewClient}>
            Add Client
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Client Modal */}
      <Modal size="lg" show={showEditClientModal} onHide={() => setShowEditClientModal(false)}>
        <Modal.Header className="employee-log-modal-header" closeButton>
          <Modal.Title className="employee-log-modal-title">Edit Client</Modal.Title>
        </Modal.Header>
        <Modal.Body className="employee-log-modal-body">
          <Form className="employee-log-form">
            <Form.Group controlId="formEditClientName">
              <Form.Label>Client Name</Form.Label>
              <Form.Control
                type="text"
                className='all-modal-form-control'
                value={newClientName}
                onChange={(e) => setNewClientName(e.target.value)}
                placeholder="Enter client's name"
              />
            </Form.Group>
            <Form.Group controlId="formEditClientPhone" className="mt-3">
              <Form.Label>Client Phone Number</Form.Label>
              <Form.Control
                type="text"
                className='all-modal-form-control'
                value={newClientPhone}
                onChange={(e) => setNewClientPhone(e.target.value)}
                placeholder="Enter client's phone number"
              />
            </Form.Group>
            <Form.Group controlId="formEditClientEmail" className="mt-3">
              <Form.Label>Client Email</Form.Label>
              <Form.Control
                type="email"
                className='all-modal-form-control'
                value={newClientEmail}
                onChange={(e) => setNewClientEmail(e.target.value)}
                placeholder="Enter client's email"
              />
            </Form.Group>
            <Form.Group controlId="formEditClientAddress" className="mt-3">
              <Form.Label>Client Address</Form.Label>
              <Form.Control
                type="address"
                className='all-modal-form-control'
                value={newClientAddress}
                onChange={(e) => setNewClientAddress(e.target.value)}
                placeholder="Enter client's Address"
              />
            </Form.Group>
            <Form.Group controlId="formEditAssignedEmployee" className='mt-3'>
              <Form.Label>Assign Employee</Form.Label>
              <Form.Select
                className='all-modal-form-control'
                value={selectedEmployee ? selectedEmployee.id : ''}
                onChange={(e) =>
                  setSelectedEmployee(
                    employees.find((emp) => emp.id === e.target.value) || null
                  )
                }
              >
                <option value="">None</option>
                {employees.map((employee) => (
                  <option key={employee.id} value={employee.id}>
                    {employee.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formEditClientStay" className="mt-4">
              <Form.Label className='stay-date'>Stay Duration</Form.Label>
              <div className="d-flex justify-content-between">
                <div>
                  <Form.Label>Start Date</Form.Label>
                  <DatePicker
                    selected={clientStayStart}
                    className='all-modal-form-control'
                    onChange={(date) => setClientStayStart(date)}
                    dateFormat="MM/dd/yyyy"
                  />
                </div>
                <div>
                  <Form.Label>End Date</Form.Label>
                  <DatePicker
                    selected={clientStayEnd}
                    onChange={(date) => setClientStayEnd(date)}
                    className='all-modal-form-control'
                    dateFormat="MM/dd/yyyy"
                  />
                </div>
              </div>
            </Form.Group>
             {/* Hourly Rate Input */}
             <Form.Group controlId="formClientRates" className="mt-3">
              <Row>
                <Col md={6}>
                  <Form.Label>Hourly Rate</Form.Label>
                  <Form.Control
                    type="number"
                    className="all-modal-form-control"
                    value={clientHourlyRate}
                    onChange={(e) => setHourlyRate(e.target.value)}
                    placeholder="Enter client's hourly rate"
                  />
                </Col>
                <Col md={6}>
                  <Form.Label>Total Hours Per Day</Form.Label>
                  <Form.Control
                    type="number"
                    className="all-modal-form-control"
                    value={totalHours}
                    onChange={(e) => setTotalHours(e.target.value)}
                    placeholder="Enter total hours per day"
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group controlId="formEditClientMarketer" className="mt-4">
              <Form.Label className='stay-date'>Marketer Information</Form.Label>
              <div className="d-flex justify-content-between">
                <div>
                  <Form.Label>Marketer</Form.Label>
                  <Form.Control
                   type="text"
                   className='all-modal-form-control'
                   value={marketerName}
                   onChange={(e) => setMarketerName(e.target.value)}
                   placeholder="Enter marketer's name"
                 />
                </div>
                <div>
                  <Form.Label>Marketer Commision</Form.Label>
                  <Form.Control
                    type="number"
                    className="all-modal-form-control"    
                    readOnly              />
                </div>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="employee-log-modal-footer">
          <Button variant="secondary" onClick={() => setShowEditClientModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveEditClient}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        show={showDeleteConfirmModal}
        onHide={() => setShowDeleteConfirmModal(false)}
      >
        <Modal.Header closeButton className="employee-log-modal-header">
          <Modal.Title style={{ fontWeight: '550', letterSpacing: '1px', fontFamily: 'Arial, Helvetica, sans-serif', color: '#333' }}>
            Confirm Delete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="employee-log-modal-body">
          <p className='mt-2 mb-2 '>Are you sure you want to delete <strong>{clientToDelete?.name}?</strong></p>
        </Modal.Body>
        <Modal.Footer className="employee-log-modal-footer">
          <Button variant="secondary" onClick={() => setShowDeleteConfirmModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Delete Client
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Toast Container for Notifications */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        // Customize toast appearance to match Dashboard's design
        toastClassName="custom-toast"
        bodyClassName="custom-toast-body"
      />
    </Container>
  );
};

export default Dashboard;