import React, { useState } from 'react';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import logo from '../images/logo.webp';
import '../styles/RateSheet.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Button, InputGroup, FormControl, Container, Row, Col, Table } from 'react-bootstrap';

const RateSheet = () => {
  const [rates, setRates] = useState({
    '1-3 Hours': 70,
    '4-6 Hours': 50,
    '7-11 Hours': 42,
    '12 Hours +': 40,
    'Live-In': 900,
  });

  const [hours, setHours] = useState({
    '1-3 Hours': 0,
    '4-6 Hours': 0,
    '7-11 Hours': 0,
    '12 Hours +': 0,
    'Live-In': 0,
  });

  const handleRateChange = (event, key) => {
    const newRate = event.target.value;
    if (newRate < 0) {
      toast.error('Rate cannot be negative.');
      return;
    }
    setRates((prevRates) => ({
      ...prevRates,
      [key]: parseFloat(newRate) || 0,
    }));
  };

  const handleHoursChange = (event, key) => {
    const newHours = event.target.value;
    if (newHours < 0) {
      toast.error('Hours cannot be negative.');
      return;
    }
    setHours((prevHours) => ({
      ...prevHours,
      [key]: parseFloat(newHours) || 0,
    }));
  };

  const calculateTotalCost = (key) => {
    const rate = rates[key] || 0;
    const hour = hours[key] || 0;
    return rate * hour;
  };

  const generateRateSheetPDF = () => {
    const doc = new jsPDF();
    const orangeColor = '#D77124';
    const margin = 20;

    const logoWidth = 40;
    const logoHeight = 20;
    doc.addImage(logo, 'WEBP', margin, 10, logoWidth, logoHeight);

    const textStartX = margin + logoWidth + 10;

    doc.setFontSize(16);
    doc.setTextColor(orangeColor);
    doc.text('Imagine Seniorcare Inc.', textStartX, 15);
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text('Email: imaginescinc@gmail.com | Phone: (831)-332-5053', textStartX, 22);
    doc.text('Website: imagineseniorcareinc.com', textStartX, 29);
    doc.text('255 El Camino Real #204, Burlingame, CA 94010', textStartX, 36);

    doc.setDrawColor(orangeColor);
    doc.setLineWidth(0.5);
    doc.line(margin, 42, doc.internal.pageSize.width - margin, 42);

    doc.setFontSize(20);
    doc.setTextColor(orangeColor);
    doc.text('Rate Sheet', margin, 55);

    autoTable(doc, {
      startY: 65,
      head: [['Hours of Care', 'Rate Per Hour', 'Hours', 'Total Cost']],
      body: Object.keys(rates).map((key) => [
        { content: key, styles: { fontStyle: 'bold' } }, // Bold Hours of Care
        key === 'Live-In' ? `$${rates[key]}/Day` : `$${rates[key]}/HR`,
        hours[key] || 0,
        `$${calculateTotalCost(key).toFixed(2)}`,
      ]),
      theme: 'grid',
      headStyles: { fillColor: orangeColor, textColor: [255, 255, 255], fontSize: 12 },
      styles: { fontSize: 12, cellPadding: 6, halign: 'center', textColor: 0 },
    });

    const totalCost = Object.keys(rates).reduce((sum, key) => sum + calculateTotalCost(key), 0);
    doc.setFontSize(16);
    doc.setTextColor(orangeColor);
    doc.text(`Total Cost: $${totalCost.toFixed(2)}`, margin, doc.lastAutoTable.finalY + 10);

    doc.save('RateSheet.pdf');
    toast.success('Rate Sheet Downloaded Successfully!');
  };

  const totalCostAll = Object.keys(rates).reduce((sum, key) => sum + calculateTotalCost(key), 0);

  return (
    <Container fluid className="timecard-container">
      {/* Toast Container for Notifications */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastClassName="rate-sheet-toast"
        bodyClassName="rate-sheet-toast-body"
      />

      {/* Header Section */}
      <Row className="align-items-center mb-3">
      <Col xs={12} md="auto" className="mb-2 mb-md-0">
          <h1 className="timecard-title">Rate Sheet</h1>
        </Col>

        {/* Button Column */}
        <Col xs={12} md className="text-md-end">
          <Button
            variant="success"
            className="filter-button dashboard-add-client-btn custom-width-button"
            onClick={generateRateSheetPDF}
          >
            <FontAwesomeIcon icon={faDownload} /> Download Rate Sheet as PDF
          </Button>
        </Col>
      </Row>

      <hr className="divider" />

      {/* Rates Table */}
      <Row>
        <Col>
          <Table striped bordered responsive className="rate-sheet-table" style={{ cursor: 'default' }}>
            <thead>
              <tr>
                <th>Hours of Care</th>
                <th>Rate Per Hour</th>
                <th>Hours</th>
                <th>Total Cost</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(rates).map((key) => (
                <tr key={key}>
                  <td>
                    <strong>{key}</strong>
                  </td>
                  <td>
                    <InputGroup style={{ minWidth: '85px' }}>
                      <InputGroup.Text>$</InputGroup.Text>
                      <FormControl
                        type="number"
                        value={rates[key]}
                        onChange={(e) => handleRateChange(e, key)}
                        className="rate-sheet-input"
                      />
                    </InputGroup>
                  </td>
                  <td>
                    <FormControl
                      type="number"
                      value={hours[key]}
                      onChange={(e) => handleHoursChange(e, key)}
                      min="0"
                      placeholder="Hours"
                      className="rate-sheet-hours"
                      aria-label={`Hours for ${key}`}
                    />
                  </td>
                  <td className="rate-sheet-total-cost">${calculateTotalCost(key).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="3" className="text-end fw-bold">
                  Total Cost:
                </td>
                <td className="rate-sheet-total-cost fw-bold">${totalCostAll.toFixed(2)}</td>
              </tr>
            </tfoot>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default RateSheet;
