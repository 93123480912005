import React, { useState, useEffect } from "react";
import { updateProfile } from "firebase/auth"; // Import the updateProfile method
import { useNavigate } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import "../styles/LoginForm.css";
import Logo from "../images/logo.webp";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { sendPasswordResetEmail } from "firebase/auth";


const LoginForm = () => {
  const [tab, setTab] = useState("login"); // Track selected tab (login/signup)
  const [email, setEmail] = useState("");
  const [emailBlured, setEmailBlured] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordBlured, setPasswordBlured] = useState(false);
  const [name, setName] = useState(""); // For sign-up name
  const [accessCode, setAccessCode] = useState(""); // For sign-up access code
  const [confirmPassword, setConfirmPassword] = useState(""); // For sign-up confirm password
  const [confirmPasswordBlured, setConfirmPasswordBlured] = useState(false);
  const [rememberMe, setRememberMe] = useState(false); // Remember Me state
  const navigate = useNavigate();
  const auth = getAuth(); // Firebase Auth instance

  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showAccessCode, setShowAccessCode] = useState(false);

  useEffect(() => {
    document.body.classList.add("hide-header-footer");
    return () => {
      document.body.classList.remove("hide-header-footer");
    };
  }, []);

  // Validation functions
  const validEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
    return re.test(email.toLowerCase());
  };

  const validPassword = (password) => password.length >= 8;

  const passwordsMatch = () => password === confirmPassword;

  // Function to validate access code by calling the HTTP Cloud Function
  const validateAccessCode = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_VALIDATE_ACCESS_CODE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ accessCode }), // Send accessCode in the request body
      });

      if (response.ok) {
        const result = await response.json();
        return result.success; // If success, return true
      } else {
        toast.error("Invalid Access Code");
        return false; // If error, return false
      }
    } catch (error) {
      toast.error("Error validating access code");
      return false;
    }
  };

  const validate = () => {
    setEmailBlured(true);
    setPasswordBlured(true);
    if (tab === "signup") {
      setConfirmPasswordBlured(true);
    }

    const isLoginValid = validEmail(email) && validPassword(password);
    const isSignUpValid = isLoginValid && passwordsMatch();

    return tab === "login" ? isLoginValid : isSignUpValid;
  };

  // Sign-up flow
  const handleSignUp = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    setIsLoading(true);
  
    const accessCodeValid = await validateAccessCode();
    if (!accessCodeValid) {
      setIsLoading(false);
      return;
    }
  
    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Update the user's profile with the name
      await updateProfile(user, {
        displayName: name, // Set the displayName to the name entered by the user
      });
  
      // Optionally, you can save the user's data to Firestore here if needed
  
      // Save authentication state
      if (rememberMe) {
        localStorage.setItem("isAuthenticated", "true"); // Save sign-up state in localStorage
      } else {
        sessionStorage.setItem("isAuthenticated", "true"); // Save sign-up state in sessionStorage
      }
  
      toast.success("Sign Up successful!");
      navigate("/admin/dashboard"); // Navigate to admin dashboard after successful sign-up
    } catch (error) {
      toast.error("Error signing up: " + error.message);
    }
    setIsLoading(false);
  };

  // Login flow
  const handleLogin = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    setIsLoading(true);

    try {
      await signInWithEmailAndPassword(auth, email, password);
      // Save authentication state
      if (rememberMe) {
        localStorage.setItem("isAuthenticated", "true"); // Save login state in localStorage
      } else {
        sessionStorage.setItem("isAuthenticated", "true"); // Save login state in sessionStorage
      }
      toast.success("Login successful!");
      navigate("/admin/dashboard"); // Navigate to admin dashboard after successful login
    } catch (error) {
      toast.error("Error logging in: " + error.message);
    }
    setIsLoading(false);
  };

  const handleForgotPassword = async () => {
    if (!validEmail(email)) {
      toast.error("Please enter a valid email to reset your password.");
      return;
    }
  
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success("Password reset email sent to " + email + "! Check your inbox.");
    } catch (error) {
      toast.error("Error sending password reset email: " + error.message);
    }
  };
  

  return (
    <div className="login-page">
      <div className="login-hero">
        <img src={Logo} alt="Logo" className="login-logo" />
      </div>

      <div className="login-container">
        <div className="login-card">
          <div className="login-tabs">
            <button className={`login-tab ${tab === "login" ? "login-active-tab" : ""}`} onClick={() => setTab("login")}>
              Login
            </button>
            <button className={`login-tab ${tab === "signup" ? "login-active-tab" : ""}`} onClick={() => setTab("signup")}>
              Sign Up 
            </button>
          </div>

          <div className="login-form-data">
            {tab === "login" ? (
              <form className="login-forms-inputs" onSubmit={handleLogin}>
                <label className="login-label">Email</label>
                <div className="login-input-container">
                  <input
                    type="text"
                    className={`login-form-control ${!validEmail(email) && emailBlured ? "login-is-invalid" : ""}`}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={() => setEmailBlured(true)}
                  />
                </div>
                {!validEmail(email) && emailBlured && <div className="login-invalid-feedback">A valid email is required!</div>}

                <label className="login-label">Password</label>
                <div className="login-input-container">
                  <input
                    type={showPassword ? "text" : "password"}
                    className={`login-form-control ${!validPassword(password) && passwordBlured ? "login-is-invalid" : ""}`}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={() => setPasswordBlured(true)}
                  />
                  <span className="login-password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
                {!validPassword(password) && passwordBlured && <div className="login-invalid-feedback">Password must be at least 8 characters!</div>}

                <div className="login-form-check">
                  <input type="checkbox" className="login-form-check-input" id="rememberMe" checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
                  <label className="login-form-check-label" htmlFor="rememberMe">
                    Remember Me
                  </label>
                </div>

                <div className="login-forgot-password">
                  <button type="button" className="login-forgot-password-btn" onClick={handleForgotPassword}>
                    Forgot Password?
                  </button>
                </div>

                <button className="login-btn" type="submit" disabled={isLoading}>
                  {isLoading ? 'Logging in...' : 'Login'}
                </button>
              </form>
            ) : (
              <form className="login-forms-inputs" onSubmit={handleSignUp}>
                <label className="login-label">Name</label>
                <div className="login-input-container">
                  <input
                    type="text"
                    className={`login-form-control ${!name && emailBlured ? "login-is-invalid" : ""}`}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <label className="login-label">Email</label>
                <div className="login-input-container">
                  <input
                    type="text"
                    className={`login-form-control ${!validEmail(email) && emailBlured ? "login-is-invalid" : ""}`}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={() => setEmailBlured(true)}
                  />
                </div>
                {!validEmail(email) && emailBlured && <div className="login-invalid-feedback">A valid email is required!</div>}

                <label className="login-label">Access Code</label>
                <div className="login-input-container">
                  <input
                    type={showAccessCode ? "text" : "password"}
                    className={`login-form-control ${!accessCode && emailBlured ? "login-is-invalid" : ""}`}
                    value={accessCode}
                    onChange={(e) => setAccessCode(e.target.value)}
                  />
                  <span className="login-password-toggle-icon" onClick={() => setShowAccessCode(!showAccessCode)}>
                    {showAccessCode ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>

                <label className="login-label">Password</label>
                <div className="login-input-container">
                  <input
                    type={showPassword ? "text" : "password"}
                    className={`login-form-control ${!validPassword(password) && passwordBlured ? "login-is-invalid" : ""}`}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={() => setPasswordBlured(true)}
                  />
                  <span className="login-password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
                {!validPassword(password) && passwordBlured && <div className="login-invalid-feedback">Password must be at least 8 characters!</div>}

                <label className="login-label">Confirm Password</label>
                <div className="login-input-container">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    className={`login-form-control ${!passwordsMatch() && confirmPasswordBlured ? "login-is-invalid" : ""}`}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onBlur={() => setConfirmPasswordBlured(true)}
                  />
                  <span className="login-password-toggle-icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
                {!passwordsMatch() && confirmPasswordBlured && <div className="login-invalid-feedback">Passwords do not match!</div>}

                <button className="login-btn" type="submit" disabled={isLoading}>
                  {isLoading ? 'Signing up...' : 'Sign Up'}
                </button>
              </form>
            )}
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default LoginForm;
