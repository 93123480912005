import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Form
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import AddLeadModal from '../components/AddLeadModal';
import EditLeadModal from '../components/EditLeadModal';
import '../styles/Leads.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const Leads = () => {
  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [showAddLeadModal, setShowAddLeadModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);

  // Filters
  const [searchName, setSearchName] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedReferral, setSelectedReferral] = useState('');
  const [selectedMarketer, setSelectedMarketer] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [sortAscending, setSortAscending] = useState(true);

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const leadsSnapshot = await getDocs(collection(db, 'leads'));
        const leadsData = leadsSnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            date: data.date instanceof Timestamp ? data.date.toDate() : new Date(data.date),
            startOfCare: data.startOfCare instanceof Timestamp ? data.startOfCare.toDate() : (data.startOfCare ? new Date(data.startOfCare) : null),
          };
        });
        setLeads(leadsData);
        setFilteredLeads(leadsData);
      } catch (error) {
        console.error('Error fetching leads:', error);
      }
    };
    fetchLeads();
  }, []);

  // Function to handle filtering
  useEffect(() => {
    let updatedLeads = [...leads];

    if (searchName) {
      updatedLeads = updatedLeads.filter(lead => lead.name.toLowerCase().includes(searchName.toLowerCase()));
    }

    if (selectedDate) {
      updatedLeads = updatedLeads.filter(lead => lead.date && lead.date.toDateString() === selectedDate.toDateString());
    }

    if (selectedReferral) {
      updatedLeads = updatedLeads.filter(lead => lead.referralSource === selectedReferral);
    }

    if (selectedMarketer) {
      updatedLeads = updatedLeads.filter(lead => lead.marketer === selectedMarketer);
    }

    if (selectedLocation) {
      updatedLeads = updatedLeads.filter(lead => lead.locationType === selectedLocation);
    }

    if (selectedStatus) {
      updatedLeads = updatedLeads.filter(lead => lead.status === selectedStatus);
    }

    updatedLeads.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return sortAscending ? -1 : 1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return sortAscending ? 1 : -1;
      return 0;
    });

    setFilteredLeads(updatedLeads);
  }, [searchName, selectedDate, selectedReferral, selectedMarketer, selectedLocation, selectedStatus, sortAscending, leads]);

  const handleOpenEditModal = (lead) => {
    setSelectedLead(lead);
    setShowEditModal(true);
  };

  const handleDeleteLead = async (id) => {
    if (!window.confirm("Are you sure you want to delete this lead?")) return;
    try {
      await deleteDoc(doc(db, 'leads', id));
      setLeads(prevLeads => prevLeads.filter(lead => lead.id !== id));
    } catch (error) {
      console.error('Error deleting lead:', error);
    }
  };

  const clearFilters = () => {
    setSearchName('');
    setSelectedDate(null);
    setSelectedReferral('');
    setSelectedMarketer('');
    setSelectedLocation('');
    setSelectedStatus('');
    setSortAscending(true);
  };

  const safeFormatDate = (date) => {
    if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
      return 'No Date';
    }
    return new Intl.DateTimeFormat('en-US').format(date);
  };
  
  return (
    <Container fluid className="timecard-container">
      <Row className="align-items-center mb-3">
        {/* Title column: full width on xs, auto on md */}
        <Col xs={12} md="auto" className="mb-2 mb-md-0">
          <h1 className="timecard-title">Leads</h1>
        </Col>

        {/* Button column: full width on xs, takes remaining space on md+ */}
        <Col xs={12} md className="text-md-end">
          <Button
            onClick={() => setShowAddLeadModal(true)}
            className="dashboard-add-client-btn"
          >
            + Add Lead
          </Button>
        </Col>
      </Row>
      <hr class="divider"></hr>

      {/* Filters */}
      <Row className="mb-3 g-2"> 
        <Col xs={12} sm={6} md={3}>
          <Form.Control type="text" placeholder="Name" value={searchName} onChange={(e) => setSearchName(e.target.value)} />
        </Col>
        <Col xs={12} sm={6} md={3}>
          <DatePicker selected={selectedDate} onChange={setSelectedDate} dateFormat="MM/dd/yyyy" className="form-control" placeholderText="Date" />
        </Col>
        <Col xs={12} sm={6} md={3}>
          <Form.Select value={selectedReferral} onChange={(e) => setSelectedReferral(e.target.value)}>
            <option value="">Referral</option>
            {[...new Set(leads.map(lead => lead.referralSource))].map(source => <option key={source} value={source}>{source}</option>)}
          </Form.Select>
        </Col>
        <Col xs={12} sm={6} md={3}>
          <Form.Select value={selectedMarketer} onChange={(e) => setSelectedMarketer(e.target.value)}>
          <option value="">Marketer</option>
            {[...new Set(leads.map(lead => lead.marketer))].map(marketer => <option key={marketer} value={marketer}>{marketer}</option>)}
          </Form.Select>
        </Col>
        <Col xs={12} sm={6} md={3}>
          <Form.Select value={selectedLocation} onChange={(e) => setSelectedLocation(e.target.value)}>
            <option value="">Location Type</option>
            <option value="Private Home">Private Home</option>
            <option value="Assisted Living">Assisted Living</option>
            <option value="Nursing Home">Nursing Home</option>
            <option value="Hospital">Hospital</option>
          </Form.Select>
        </Col>
        <Col xs={12} sm={6} md={3}>
          <Form.Select value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
            <option value="">Status</option>
            <option value="Pending">Pending</option>
            <option value="Contacted">Contacted</option>
            <option value="Qualified">Qualified</option>
            <option value="Converted">Converted</option>
            <option value="Lost">Lost</option>
          </Form.Select>
        </Col>
        <Col xs={12} sm={6} md={3}>
          <Button onClick={() => setSortAscending(!sortAscending)}>Sort Name {sortAscending ? '↓' : '↑'}</Button>
        </Col>
        <Col xs={12} sm={6} md={3}>
          <Button variant="secondary" onClick={clearFilters}>Clear Filters</Button>
        </Col>
      </Row>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Lead Name</th>
            <th>Date</th>
            <th>Contact Info</th>
            <th>Referral Source</th>
            <th>Marketer</th>
            <th>Location Type</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredLeads.map((lead) => (
            <tr key={lead.id} onClick={() => handleOpenEditModal(lead)}>
              <td>{lead.name}</td>
              <td>{lead.date ? safeFormatDate(lead.date) : 'N/A'}</td>
              <td>{lead.contactInfo || 'N/A'}</td>
              <td>{lead.referralSource || 'N/A'}</td>
              <td>{lead.marketer || 'N/A'}</td>
              <td>{lead.locationType || 'N/A'}</td>
              <td>{lead.status || 'Pending'}</td>
              <td>
                <Button variant="danger" size="sm" onClick={(e) => { e.stopPropagation(); handleDeleteLead(lead.id); }}>
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <AddLeadModal 
        show={showAddLeadModal} 
        handleClose={() => setShowAddLeadModal(false)} 
        onLeadAdded={(newLead) => setLeads([...leads, newLead])} 
      />
      <EditLeadModal 
        show={showEditModal} 
        handleClose={() => setShowEditModal(false)} 
        lead={selectedLead} 
        onLeadUpdated={(updatedLead) => {
          setLeads((prevLeads) =>
            prevLeads.map((lead) => (lead.id === updatedLead.id ? updatedLead : lead))
          );
        }} 
      />
    </Container>
  );
};

export default Leads;
