import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

const EditLeadModal = ({ show, handleClose, lead, onLeadUpdated }) => {
  const [updatedLead, setUpdatedLead] = useState({
    name: '',
    date: new Date(),
    contactInfo: '',
    contactPerson: '',
    address: '',
    referralSource: '',
    marketer: '',
    startOfCare: null,
    locationType: 'Private Home',
    status: 'Pending',
  });

  // Load the selected lead's data when the modal opens
  useEffect(() => {
    if (lead) {
      setUpdatedLead({
        ...lead,
        date: lead.date ? new Date(lead.date) : new Date(),
        startOfCare: lead.startOfCare ? new Date(lead.startOfCare) : null,
      });
    }
  }, [lead]);

  const handleChange = (e) => {
    setUpdatedLead({ ...updatedLead, [e.target.name]: e.target.value });
  };

  const handleDateChange = (field, date) => {
    setUpdatedLead({ ...updatedLead, [field]: date });
  };

  const handleSave = async () => {
    if (!lead || !lead.id) return;
    
    try {
      const leadRef = doc(db, 'leads', lead.id);
      await updateDoc(leadRef, updatedLead); // Update Firestore

      onLeadUpdated(updatedLead); // Update UI
      handleClose(); // Close modal
    } catch (error) {
      console.error('Error updating lead:', error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Lead</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Lead Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={updatedLead.name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Contact Person</Form.Label>
                <Form.Control
                  type="text"
                  name="contactPerson"
                  value={updatedLead.contactPerson}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Contact Info</Form.Label>
                <Form.Control
                  type="text"
                  name="contactInfo"
                  value={updatedLead.contactInfo}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Referral Source</Form.Label>
                <Form.Control
                  type="text"
                  name="referralSource"
                  value={updatedLead.referralSource}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Date</Form.Label>
                <DatePicker
                  selected={updatedLead.date}
                  onChange={(date) => handleDateChange('date', date)}
                  dateFormat="MM/dd/yyyy"
                  className="form-control"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Start of Care</Form.Label>
                <DatePicker
                  selected={updatedLead.startOfCare}
                  onChange={(date) => handleDateChange('startOfCare', date)}
                  dateFormat="MM/dd/yyyy"
                  className="form-control"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Marketer</Form.Label>
                <Form.Control
                  type="text"
                  name="marketer"
                  value={updatedLead.marketer}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  value={updatedLead.address}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Location Type</Form.Label>
                <Form.Select
                  name="locationType"
                  value={updatedLead.locationType}
                  onChange={handleChange}
                >
                  <option value="Private Home">Private Home</option>
                  <option value="Assisted Living">Assisted Living</option>
                  <option value="Nursing Home">Nursing Home</option>
                  <option value="Hospital">Hospital</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Form.Select
                  name="status"
                  value={updatedLead.status}
                  onChange={handleChange}
                >
                  <option value="Pending">Pending</option>
                  <option value="Contacted">Contacted</option>
                  <option value="Qualified">Qualified</option>
                  <option value="Converted">Converted</option>
                  <option value="Lost">Lost</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditLeadModal;
