import {
    Document,
    Packer,
    Paragraph,
    TextRun,
    Table,
    TableRow,
    TableCell,
    AlignmentType,
    WidthType,
} from "docx";
import { saveAs } from "file-saver";

const generateDOCX = async (invoice) => {
    const orangeColor = "D77124";

    if (!invoice) {
        console.error("Invoice data is missing:", invoice);
        return;
    }

    const totalCharges = invoice.invoiceAmount
        ? parseFloat(invoice.invoiceAmount).toFixed(2)
        : "0.00";
    const timesheets = Array.isArray(invoice.timesheets) ? invoice.timesheets : [];

    if (!invoice.timesheets || invoice.timesheets.length === 0) {
        console.error("Error: No timesheets found for this invoice", invoice.timesheets);
        return;
    }
    
    // Extract and sort timesheet dates
    const timesheetDates = invoice.timesheets
        .map(entry => entry.date) // Keep as a string (YYYY-MM-DD format)
        .sort(); // Sort lexicographically
    
    const billingStartDate = timesheetDates[0]; // First date in the list
    const billingEndDate = timesheetDates[timesheetDates.length - 1]; // Last date in the list    

    const doc = new Document({
        sections: [
            {
                properties: {},
                children: [
                    // Company Header
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: "Imagine Seniorcare Inc.",
                                bold: true,
                                color: orangeColor,
                                size: 32,
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: "Email: imaginescinc@gmail.com | Phone: (831)-332-5053",
                                size: 24,
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: "Website: imagineseniorcareinc.com",
                                size: 24,
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: "255 El Camino Real #204, Burlingame, CA 94010",
                                size: 24,
                            }),
                        ],
                    }),

                    // Divider
                    new Paragraph({
                        text: "─────────────────────────────────────────",
                        alignment: AlignmentType.CENTER,
                        spacing: { after: 100 },
                    }),

                    // Invoice Title
                    new Paragraph({
                        text: "Invoice",
                        bold: true,
                        color: orangeColor,
                        alignment: AlignmentType.CENTER,
                        spacing: { after: 200 },
                        size: 32,
                    }),

                    // Invoice Details
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: `Invoice Number: ${invoice.invoiceNumber || "N/A"}`,
                                size: 24,
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: `Date: ${
                                    invoice.createdAt
                                        ? new Date(invoice.createdAt).toLocaleDateString()
                                        : "N/A"
                                }`,
                                size: 24,
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: `Client Name: ${invoice.clientName || "N/A"}`,
                                size: 24,
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: `Client Address: ${invoice.clientAddress || "N/A"}`,
                                size: 24,
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: `Service Period: ${billingStartDate} - ${billingEndDate}`,
                                size: 24,
                            }),
                        ],
                    }),

                    new Paragraph({ text: "", spacing: { after: 200 } }),

                    // Invoice Table - **Now Full Page Width**
                    new Table({
                        width: { size: 100, type: WidthType.PERCENTAGE },
                        columnWidths: [2000, 1500, 2000, 2000], // Fixed column widths in twips (1/20th of a point)
                        rows: [
                            // Table Header with **Wider Columns**
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                text: "Date",
                                                bold: true,
                                                alignment: AlignmentType.CENTER,
                                            }),
                                        ],
                                    }),
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                text: "Hours",
                                                bold: true,
                                                alignment: AlignmentType.CENTER,
                                            }),
                                        ],
                                    }),
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                text: "Hourly Rate",
                                                bold: true,
                                                alignment: AlignmentType.CENTER,
                                            }),
                                        ],
                                    }),
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                text: "Total",
                                                bold: true,
                                                alignment: AlignmentType.CENTER,
                                            }),
                                        ],
                                    }),
                                ],
                            }),

                            // Table Data **(More Readable Columns)**
                            ...timesheets.map((entry) => {
                                const totalCharge = entry.totalCharges
                                    ? parseFloat(entry.totalCharges).toFixed(2)
                                    : "0.00";
                                const hourlyRate = entry.hourlyRate
                                    ? parseFloat(entry.hourlyRate).toFixed(2)
                                    : "0.00";
                                const totalHours = entry.totalHours ? entry.totalHours : "0";
                                const entryDate = entry.date
                                    ? new Date(new Date(entry.date).getTime() + 24 * 60 * 60 * 1000).toLocaleDateString()
                                    : "N/A";
                                return new TableRow({
                                    children: [
                                        new TableCell({
                                            children: [
                                                new Paragraph({
                                                    text: entryDate,
                                                    alignment: AlignmentType.CENTER,
                                                }),
                                            ],
                                        }),
                                        new TableCell({
                                            children: [
                                                new Paragraph({
                                                    text: `${totalHours} hrs`,
                                                    alignment: AlignmentType.CENTER,
                                                }),
                                            ],
                                        }),
                                        new TableCell({
                                            children: [
                                                new Paragraph({
                                                    text: `$${hourlyRate}`,
                                                    alignment: AlignmentType.CENTER,
                                                }),
                                            ],
                                        }),
                                        new TableCell({
                                            children: [
                                                new Paragraph({
                                                    text: `$${totalCharge}`,
                                                    alignment: AlignmentType.CENTER,
                                                }),
                                            ],
                                        }),
                                    ],
                                });
                            }),

                            // **Total Row Fix**
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [],
                                        columnSpan: 2,
                                    }),
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                text: "Total Invoice Amount:",
                                                bold: true,
                                                alignment: AlignmentType.RIGHT,
                                            }),
                                        ],
                                    }),
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                text: `$${totalCharges}`,
                                                bold: true,
                                                alignment: AlignmentType.CENTER,
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                        ],
                    }),

                    new Paragraph({ text: "", spacing: { after: 200 } }),

                    // **Terms & Conditions**
                    new Paragraph({
                        text: "Terms & Conditions",
                        bold: true,
                        color: orangeColor,
                        alignment: AlignmentType.CENTER,
                        spacing: { after: 100 },
                        size: 28,
                    }),
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: "• Payment for the invoice must be made upon receiving this invoice.",
                                size: 24,
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: "• Please make all checks payable to Imagine Seniorcare Incorporated.",
                                size: 24,
                            }),
                        ],
                    }),
                ],
            },
        ],
    });

    // Generate and download DOCX
    const blob = await Packer.toBlob(doc);
    saveAs(blob, `Invoice_${invoice.clientName}.docx`);
};

export default generateDOCX;